<template>
  <b-img
    center
    class="mb-3 mt-3"
    src="@/assets/images/logo/CuidoLogo.png"
    height="57.5px"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>
