<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <image-logo />

        <b-card-title class="mb-2 text-center">
          Recuperação de senha
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Digite abaixo o seu endereço de e-mail cadastrado:
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Digite aqui seu E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="e-mail"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="passwordEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <alert-message :status="status" />

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              class="mt-2"
              :disabled="invalid"
              @click="validationForm"
            >
              CONTINUAR
            </b-button>

            {{ ip }}

            <div class="mt-2 mb-2 text-center">
              <b-link
                :to="{name:'auth-login'}"
              >
                VOLTAR
              </b-link>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <modal-message />
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import AlertMessage from '@/layouts/components/AlertMessage.vue'
import ImageLogo from '@/layouts/components/ImageLogo.vue'
import ModalMessage from './Password-message.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    AlertMessage,
    ImageLogo,
    ModalMessage,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      passwordEmail: '',
      ip: '',
      status: {},
      required,
      email,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    showMessage() {
      return this.$store.state.password.message
    },
  },

  watch: {
    showMessage(value) {
      if (value === true) {
        this.$bvModal.show('message')
      }
    },
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])
  },

  methods: {
    logout() {
    // Remove userData from localStorage
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)
    },
    validationForm() {
      // Reset UPDATE_SHOW_MESSAGE
      this.$store.commit('password/UPDATE_SHOW_MESSAGE', false)

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('password/sendEmail', { email: this.passwordEmail })
            .then(() => {
              this.$store.commit('password/UPDATE_SHOW_MESSAGE', true)
              this.logout()
            })
            .catch(error => {
              const object = Object.is(error)
              if (object === true) {
                this.status = {
                  error: true,
                  color: 'danger',
                  message: 'Erro interno. Tente novamente.',
                }
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.card-body {
  padding-bottom: 1px;
}
</style>
