<template>

  <b-modal
    id="message"
    centered
    title="Confirmação"
    ok-only
    ok-title="OK"
    @ok="close"
    @hidden="close"
  >
    <template>
      <b-img
        src="@/assets/images/icons/check-circular-blue.png"
        color="red"
        class="m-1"
        center
        width="69.28"
      />
      <br>
      <p class="text-justify">
        Enviamos para seu e-mail as instruções para redefinir sua senha. Caso não encontre, verifique em sua caixa de Spam ou certifique-se de que você possui cadastro na Cuido.
      </p>
    </template>
  </b-modal>

</template>

<script>
import {
  VBModal, BModal, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  methods: {
    close() {
      this.$store.commit('password/UPDATE_SHOW_MESSAGE', false)
      this.$router.push('/login')
    },
  },
}
</script>

<style scoped>
  .modal .modal-header {
    background-color: #0058F9;
  }
  h5 {
     color: white;
  }
</style>
